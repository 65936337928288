import React, { useState, useEffect } from "react"
import { useFlexSearch } from "react-use-flexsearch"
import { graphql } from "gatsby"
import { slugify } from "../helpers/formatters";
import { SearchIcon, CloseIcon } from "../assets/images"
import Layout from "../components/layout"
import LinkBtn from "../components/linkBtn"
import Img from "gatsby-image"

const DirectoryPage = ({ data, location }) => {
  const titleSponsors = data.allContentfulTitleSponsor.edges;
  const premierSponsors = data.allContentfulPremierSponsor.edges;
  const goldSponsors = data.allContentfulGoldSponsor.edges;
  const boothSponsors = data.allContentfulBoothSponsor.edges;
  const index = data.localSearchVendors.index;
  const storeUrl = data.localSearchVendors.publicStoreURL;
  const [store, setStore] = useState([])
  const [query, setQuery] = useState('')
  const [saved, setSaved] = useState(false)
  const results = useFlexSearch(query, index, store, {
    encode: "icase"
  })

  // Client-side runtime data fetching to avoid big page load
  useEffect(() => {
    // get data from static folder
    fetch(`${location.origin}${storeUrl}`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setStore(resultData)
        setTimeout(() => setSaved(true), 5000)
      })
  }, [])

  const handleChange = (e) => {
    setQuery(e.target.value);
  }

  const handleClear = (e) => {
    e.preventDefault();
    setQuery('');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  return (
    <Layout mainClass="home">
      <div className="hero">
        <div className="hero__image hero__image--directory">
          <Img fluid={data.exhibitHall2.childImageSharp.fluid} alt="Exhibit hall" />
        </div>
        <header className="hero__message">
          <h1>Exhibit Hall</h1>
          <form
            className="search-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="search-form__group">
              <label htmlFor="search">
                <span className="is-visually-hidden">Search</span>
                <SearchIcon />
              </label>
              <input 
                name="search" 
                type="search" 
                placeholder="Type to search" 
                onChange={handleChange} 
                value={query}
              />
              <button type="button" className="clear" onClick={handleClear}>
                <span className="is-visually-hidden">Clear</span>
                <CloseIcon />
              </button>
            </div>
          </form>
        </header>
      </div>

      {query && results.length > 0 && (
        <div className="content-area">
          <section className="sponsor-group sponsor-group--title">
            <h2>Search results for “{query}”</h2>
            <ul>
              {results.map(result => (
                <Vendor
                  key={result.id}
                  name={result.vendorName}
                />
              ))}
            </ul>
          </section>
        </div>
      )}
      
      {results.length === 0 && (
        <div>
          <div className="content-area">
            {saved && query && query.length > 4 && (
              <div className="search-no-results">
                <p className="alert" role="alert">No search results found for “{query}”.</p>
              </div>
            )}
            <section className="sponsor-group sponsor-group--title">
              <h2>Title Sponsor</h2>
              <ul>
                <li>
                  <Vendor
                    name="The Legacy Senior Communities"
                    logo={data.legacySeniorCommunities.childImageSharp.fluid} 
                  />
                </li>
                {titleSponsors.map(edge => {
                  const { vendorName, vendorLogo } = edge.node;
                  return (
                    <li key={vendorName}>
                      <Vendor name={vendorName} logo={vendorLogo?.fluid} />
                    </li>
                  )
                })}
              </ul>
            </section>

            <section className="sponsor-group sponsor-group--premier">
              <h2>Premier Sponsors</h2>
              <ul>
                {premierSponsors.map(edge => {
                  const { vendorName, vendorLogo } = edge.node;
                  return (
                    <li key={vendorName}>
                      <Vendor name={vendorName} logo={vendorLogo?.fluid} />
                    </li>
                  )
                })}
              </ul>
            </section>
          </div>

          <div className="hero hero--separator">
            <div className="hero__image hero__image--directory">
              <Img fluid={data.exhibitHall3.childImageSharp.fluid} alt="Exhibit hall" />
            </div>
          </div>

          <div className="content-area">
            <section className="sponsor-group sponsor-group--gold">
              <h2>Gold Sponsors</h2>
              <ul>
                {goldSponsors.map(edge => {
                  const { vendorName, vendorLogo } = edge.node;
                  return (
                    <li key={vendorName}>
                      <Vendor name={vendorName} logo={vendorLogo?.fluid} />
                    </li>
                  )
                })}
              </ul>
            </section>

            <section className="sponsor-group sponsor-group--booth">
              <h2>Booth Sponsors</h2>
              <ul>
                {boothSponsors.map(edge => {
                  const { vendorName, vendorLogo } = edge.node;
                  return (
                    <li key={vendorName}>
                      <Vendor name={vendorName} logo={vendorLogo?.fluid} />
                    </li>
                  )
                })}
              </ul>
            </section>
          </div>
        </div>
      )}
    </Layout>
  )
}

const Vendor = ({ name, logo }) => (
  <LinkBtn to={`${slugify(name)}`} className="sponsor__link">
    {logo ? (
      <>
        <div className="sponsor__logo">
          <Img fluid={logo} alt={`${name} logo`} />
        </div>
        <div className="sponsor__logo sponsor__logo--name">
          <span>{name}</span>
        </div>
      </>
    ) : (
      <div className="sponsor__logo sponsor__logo--name">
        <span>{name}</span>
      </div>
    )}
  </LinkBtn>
)

export const query = graphql`
  query DirectoryPageQuery {
    allContentfulBoothSponsor(
      sort: {fields: vendorName, order: ASC},
      filter: {expoYear: {in: ["2023"]}}
    ) {
      edges {
        node {
          vendorName
          vendorLogo {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulGoldSponsor(
      sort: {fields: vendorName, order: ASC},
      filter: {expoYear: {in: ["2023"]}}
    ) {
      edges {
        node {
          vendorName
          vendorLogo {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulPremierSponsor(
      sort: {fields: vendorName, order: ASC},
      filter: {expoYear: {in: ["2023"]}}
    ) {
      edges {
        node {
          vendorName
          vendorLogo {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulTitleSponsor(
      sort: {fields: vendorName, order: ASC},
      filter: {expoYear: {in: ["2023"]}}
    ) {
      edges {
        node {
          vendorName
          vendorLogo {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    localSearchVendors {
      index
      publicStoreURL
    }
    exhibitHall2: file(relativePath: { eq: "exhibit-walk-2022.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    exhibitHall3: file(relativePath: { eq: "exhibit-walk-2022-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    legacySeniorCommunities: file(relativePath: { eq: "legacy-logo-2023.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default DirectoryPage